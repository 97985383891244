<template>
  <b-modal
    id="add-constraint-modal"
    title=" Create Constraint"
    size="lg"
    @ok="submitFunction"
    @show="getData"
  >
    <form ref="form">
      <b-row>
        <b-col cols="12">
          <b-form-group label="Name/Tag: " label-for="constraint-name">
            <vue-autosuggest
              id="constraint-name"
              v-model="name"
              :suggestions="filteredOptions"
              :limit="10"
              :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Select a predefined constraint or make your own'}"
              @input="onInputChange"
              @selected="populateOtherFields"
            >
              <template slot-scope="{suggestion}">
                <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
              </template>
            </vue-autosuggest>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group label="Description/Gist: " label-for="constraint-gist">
            <b-form-textarea
              id="constraint-gist"
              v-model="gist"
              rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Scale: " label-for="constraint-scale">
            <b-form-textarea
              id="constraint-scale"
              v-model="scale"
              rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Meter: " label-for="constraint-meter">
            <b-form-textarea
              id="constraint-meter"
              v-model="meter"
              rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Must (Essential) " label-for="constraint-must">
            <b-form-input
              id="constraint-must"
              v-model="must"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Plan (Very Important) " label-for="constraint-plan">
            <b-form-input
              id="constraint-plan"
              v-model="plan"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Stretch (Important) " label-for="constraint-stretch">
            <b-form-input
              id="constraint-stretch"
              v-model="stretch"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Wish (Desirable) " label-for="constraint-wish">
            <b-form-input
              id="constraint-wish"
              v-model="wish"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Qualifier " label-for="constraint-qualifier">
            <b-form-input
              id="constraint-qualifier"
              v-model="qualifier"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Notes: " label-for="constraint-note">
            <b-form-textarea
              id="constraint-note"
              v-model="note"
              rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="add_c_clas">Classification</label>
          <b-form-select
            id="add_c_clas"
            v-model="classification"
            :options="securityClassifications"
          />
        </b-col>
      </b-row>
      <!-- Requirements -->
      <b-row class="mb-2">
        <b-col>
          <list-group-requirement
            label="Requirements"
            :entity-array="requirements"
            modal="associator-generic-requirements-edit"
          />
        </b-col>
      </b-row>
    </form>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Discard
      </b-button>

      <b-button
        variant="success"
        :disabled="loading_status"
        @click="ok()"
      >
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Creating Constraint...
        </span>
        <span v-else>
          Create Constraint
        </span>
      </b-button>
    </template>

    <!-- Requirements -->
    <associator-generic
      name="Requirements"
      suffix="-edit"
      :associated-items="selectedRequirementsMapped"
      :all-items="allRequirementsMapped"
      @associated="linkRequirements"
    />
  </b-modal>
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { mapGetters, mapState } from 'vuex'
import ListGroupRequirement from '@/components/Forms/ListGroups/ListGroupRequirement.vue'
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue'
import {
  BButton, BCol, BFormGroup, BFormInput, BFormTextarea, BModal, BRow, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    VueAutosuggest,
    BRow,
    BCol,
    BFormGroup,
    BSpinner,
    BModal,
    BFormTextarea,
    BFormInput,
    BButton,
    ListGroupRequirement,
    AssociatorGeneric,
  },
  data() {
    return {
      filteredOptions: [],
      model: this.$store.state.model.id,
      name: '',
      gist: '',
      scale: '',
      meter: '',
      must: '',
      plan: '',
      stretch: '',
      wish: '',
      note: '',
      qualifier: '',
      classification: this.$store.state.constants.defaultSecurityClassification.id,
      requirements: [],
      allRequirements: [],
      loading_status: false,
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
    ...mapGetters({
      qualityAttributes: 'constants/qualityAttributes',
      securityClassifications: 'constants/securityClassifications',
    }),
    allRequirementsMapped() {
      return this.allRequirements.map(item => ({
        value: {
          id: item.id,
          toSortBy: item.display_id,
        },
        text: `${item.display_id}. ${item.text.replace(/<\/?[^>]+(>|$)/g, '')}`,
      }))
    },
    selectedRequirementsMapped() {
      return this.requirements.map(item => ({
        value: {
          id: item.id,
          toSortBy: item.display_id,
        },
        text: `${item.display_id}. ${item.text.replace(/<\/?[^>]+(>|$)/g, '')}`,
      }))
    },
  },
  watch: {
    default(newVal) { this.value = newVal },
    selected_constraint(newVal) {
      const t = this.payload
      t.name = newVal.name
      t.gist = newVal.gist
      t.scale = newVal.scale
      t.meter = newVal.meter
      t.must = newVal.must
      t.plan = newVal.plan
      t.stretch = newVal.stretch
      t.wish = newVal.wish
      t.qualifier = newVal.qualifier
      t.note = newVal.note
      t.classification = newVal.classification || this.$store.state.constants.defaultSecurityClassification.id
      t.requirements = newVal.requirements || []
    },
  },
  methods: {
    getData() {
      this.requirements = []
      const modelId = this.$store.state.model.id
      this.$http.get('/api/v2/requirements/get_requirements_simple', { params: { model: modelId } })
        .then(({ data }) => {
          this.allRequirements = data
        })
    },
    submitFunction(e) {
      e.preventDefault()
      this.loading_status = true
      const selectedId = this.selected_entity2.context.details.id
      const payload = {
        model: this.$store.state.model.id,
        name: this.name,
        gist: this.gist,
        scale: this.scale,
        meter: this.meter,
        must: this.must,
        plan: this.plan,
        stretch: this.stretch,
        wish: this.wish,
        qualifier: this.qualifier,
        classification: this.classification,
        note: this.note,
        requirements: this.requirements,
      }
      this.$http.post(`/api/v2/domain_model/add_constraint/${selectedId}`, payload).then(async result => {
        this.loading_status = false
        this.$bvModal.hide('add-constraint-modal')
        await this.$store.dispatch('domainModel/selectEntity2', selectedId)
        await this.$store.dispatch('domainModel/selectConstraint', result.data)
        this.$bvModal.show('edit-constraint-modal')
      })
    },
    populateOtherFields(text) {
      // Populates the `gist` and `scale` fields after you select a predefined name
      // The data should be with the name together
      this.name = text.item.name
      this.gist = text.item.gist
      this.scale = text.item.scale
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filteredData = this.qualityAttributes.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    linkRequirements(reqs) {
      const temp = this.findItemById(reqs, this.allRequirements)

      this.requirements = temp.map(x => ({
        id: x.id,
        display_id: x.display_id,
        object_text: x.text,
        priority: x.priority,
      }))
    },
    findItemById(toAssociate, allItems) {
      // Associators generic spit out arrays of just IDs
      // all link methods need ID and Name
      // this method will find the full object by ID from the all items array
      function sortFunction(value) {
        return allItems.find(item => (item.id === value))
      }

      return toAssociate.map(sortFunction)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-autosuggest.scss';
</style>
